"use client";

import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";
interface CallbackContextProps {
  callback: ((param?: any) => void) | null;
  setCallback: Dispatch<SetStateAction<((param?: any) => void) | null>>;
  id: string | null;
  setId: Dispatch<SetStateAction<string | null>>;
  needReload: boolean;
  setNeedReload: Dispatch<SetStateAction<boolean>>;
}
export const callbackContext = createContext<CallbackContextProps | null>(null);
function CallbackContextProvider({
  children
}: {
  children: ReactNode;
}) {
  const [callback, setCallback] = useState<((param?: any) => void) | null>(null);
  const [id, setId] = useState<string | null>(null);
  const [needReload, setNeedReload] = useState<boolean>(false);
  return <callbackContext.Provider value={{
    callback,
    setCallback,
    id,
    setId,
    setNeedReload,
    needReload
  }} data-sentry-element="unknown" data-sentry-component="CallbackContextProvider" data-sentry-source-file="callback-context-provider.tsx">
      {children}
    </callbackContext.Provider>;
}
export default CallbackContextProvider;